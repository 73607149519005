@import '../../assets/global.scss';
@import '../../assets/variables.scss';
@import '../../assets/mixins.scss';

.Social{display:inline-block;
  li {display: inline-block; margin:0 5px;
    &:last-child{margin-right:0;}
  }
  a, span {color: $black;}
  svg {width:18px; height:18px;}

  &.header svg {width:17px; height:17px;}

  &.footer {
    li{margin:0 15px;}
    // a, span {color: $white;}
  }
  
  &.drawer{
    a,span,svg{color:$white;}
  }
  &.round {
    span {color: $white;}
    span {background-color: $black; border-radius: 50%; width:31px; height:31px; line-height: 1.6em;}
  }
}
@import '../../assets/global';
@import '../../assets/variables';
@import '../../assets/mixins';

.Header{@include sec-default();z-index:2;//9;
  .waveWrap{position:absolute;width:100%;height:110px;left:0;overflow:hidden; z-index:-1;bottom:0;
    .wave{height:100%;width:100%;}
  }

  .waveArea{@include sec-default();background-color:$color1;position:relative;height:160px;z-index:2;}
  .nmTop{@include sec-default();padding-top:10px;}


  .hdCol{display:inline-block;}
  .logoLinkMob{display:none;}
  .navArea{float:right;text-align:center;margin-top:-10px;}
  li{display:inline-block;vertical-align:top;margin:0 15px;
    a{color:$black;}
  }
  .hsLinkRev{padding:5px 17px 6px;letter-spacing:1px;font-weight:800;}
  .homelink,.mobOnly{display:none;}
  .mobNav{display:none;}

  // .info{display:none;}
  .ilNav{float:right;margin-top:3px;text-align:center;
    .navArea{margin-top:0;}
    .navItem > a{color:$white;}
  }
  .colLogo{margin-left:-80px; margin-left:-90px;}
  .logoImg{max-width:400px;margin-bottom:10px;margin-top:20px;}
  .infoB{width:100%;float:left;text-align:center;margin-left:-10px;
    a{color:$white;font-size:16px;}
    .clearfix{display:none;}
  }
  .callLink{margin-left:10px;}
  .emailLink{margin-right:10px;}


  .navImg{height:23px;margin-top:3px;padding-top:2px;}
  .navItem{position:relative;z-index:999;}
  .navArea .dropItem{position:absolute;z-index:999;width:100%;min-width:190px;text-align:left;padding:10px;box-shadow:0px 10px 30px rgba($black,.2);
    background-color:$color1; //background-color:#fafafa;
    
    li{width:100%;margin:7px 0;}
    a{font-size:17px;color:$white;}
  }
  

  @media (max-width:1500px){
    .colLogo{margin-left:-80px;}
    .infoB{text-align:left;margin-left:0;}
  }
  @include bp(tw){
    .colLogo{margin-left:-70px;}
    .logoImg{margin-top:12px;}
    .ilNav{margin-right:-5%;}

    .navImg{height:21px;}
    .navItem{font-size:18px;margin:0 12px;}
    .navArea .dropItem{
      li{margin:5px 0;}
      a{font-size:17px;}
    }

    .infoB{
      .callLink{margin-left:7px;}
      .emailLink{margin-right:7px;}
    }
  }

  @include bp(tb){
    .colLogo{margin-left:-50px;}
    .ilNav{margin-top:4px;}
    .navItem{font-size:17px;}
    .navArea .dropItem a{font-size:16px;}
    .infoB{
      .callLink{float:left;display:block;margin-left:0;}
      .clearfix{display:block;}
    }
  }

  @include bp(ns){
    .waveWrap{height:120px;}
    .hdWrap{width:95%;}

    .nmTop{padding-top:0px;}
    .colLogo{margin-left:0;}
    .logoImg{max-width:350px;margin:20px 0 10px;}
    .ilNav{display:none;}
    .mobNav{display:block;float:right;margin-right:15%;margin-top:6px;}// margin-top:-3px;}
  }
  @include bp(og){
    .logoImg{max-width:320px;margin-top:14px;}
    .infoB .callLink{font-size:15px;}
  }
  @include bp(oc){
    .waveArea{height:90px;}
    .waveWrap{height:50px;}
    
    .logoImg{max-width:280px;margin:12px 0 0;}
    .infoB{display:none;}
    .mobNav{margin-right:0;}
  }

  @include bp(sm){.logoImg{max-width:230px;}}
}

.menuIcon{fill:$white;@include sec-default();height:28px;
  svg{width:30px;height:30px;}
  @include bp(sm){
    svg{width:24px;height:24px;}
  }
}

.SideNavList{padding:20px 30px 0;
  .SideNav{margin-top:10%;}
  .closeIcon{color:$white;position:absolute;right:10px;top:10px;cursor:pointer;font-weight:normal;
    svg{width:30px;height:30px;}
  }

  li{@include sec-default();margin:0 0 30px;line-height:1em;
    a, button{font-weight:300;color:$white;text-transform:none;font-size:18px;line-height:1em;}
    button{padding:0;}
    span{font-size:15px;font-weight:normal;color:$white;font-style:italic;text-transform:none;}

    .parentRule{display:none;}
    &.withDrop{margin-bottom:0;}
  }
  .dropItem{visibility:visible;height:auto;}
  .navImg{height:23px;margin-top:3px;padding-top:2px;}

  .mobOnly{display:block;}
  .fwOnly{display:none;}

  @include bp(sm){padding:20px 20px 0;}
}
.drawerContact{@include sec-default();padding-left:30px;
  hr{width:85%;border-color:rgba($white,.3);}

  .dcItem{@include sec-default();margin-bottom:15px;}

  p:not(.cat){font-weight:300;font-size:17px;}
  p,a{color:$white;}
  p.cat{font-size:15px;}

  @include bp(sm){padding-left:20px;}
}
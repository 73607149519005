// background: linear-gradient(to bottom left, cyan 50%, palegoldenrod 50%);
@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.SEMPage{@include sec-default();
  .sem2{padding:220px 0;
    .s2Content{position:relative;z-index:1;}
    .left{width:49%;text-align:right;padding-right:20px;margin-top:5px;}
    .right{width:45%;margin-right:2%;}
    
    p.secTitle,p.h3{font-family:$mont;font-size:25px;letter-spacing:4px;text-transform:uppercase; font-weight:500;margin-top:20px;}// IF YOU GO WITH THIS, RENAME THE CLASS
    
    .logoRow{@include sec-default();
      img{display:inline-block;height:100px;margin:0 10px;}
      .bing{background:$bing;padding:20px 0;}
    }

    @media (min-width:2000px){padding:250px 0;}
    @media (min-width:2400px){padding:280px 0;}
    @include bp(tw){padding:160px 0;}
    @include bp(tb){p{font-size:18px;}}
    @include bp(ns){padding:120px 0;
      .left{padding-right:15px;margin-top:15px;}
      .right{margin-right:0;width:47%;}
    }
    @include bp(tn){.left{padding-right:5px;}}
    
    @include bp(og){padding:100px 0;
      &::after{display:none;}  
      .medDef{width:85%;}
      .left,.right{@include sec-default();text-align:center;}
      .left{margin-top:20px;}
      p{font-size:17px;}

      .logoRow{
        img{height:80px;margin:0 5px;}
      }
    }

    @include bp(oc){padding:70px 0;
      .medDef{width:95%;}
    }
    @media (max-width:500px){margin-top:20px;}
  }


  .sem3{padding:40px 0 200px;overflow:hidden;
    p.secTitle{font-size:48px;line-height:1.1em;max-width:270px;}

    .col{position:relative;z-index:0;
      &::before{@include abs();left:0;border-radius:50%;top:0;}
      p{color:$white;}
    }
    .left{margin-top:120px;padding-top:100px;padding-left:30px;
      &::before{background-color:$color1-dk;width:320px;height:320px;left:-10px;}
      p{max-width:230px;}
    }
    .mid{margin-top:40px;padding-top:150px;padding-left:20px;
      &::before{background-color:$color2;width:440px;height:440px;left:-50px;}

      p{max-width:280px;}
    }
    .right{padding-top:110px;padding-left:80px;
      &::before{background-color:$peach;width:580px;height:580px;top:-40px;}

      p{line-height:1.3em;}
    }
    
    .item{@include sec-default2();margin-right:-7%;margin-bottom:20px;}

    @media (min-width:1800px){padding-top:0;}
    @media (min-width:2100px){margin-top:-30px;z-index:1;position: relative;}

    @include bp(tw){padding:20px 0 140px;
      .medDef{max-width:800px;}
      .left{width:100%;margin-top:0;padding:0;margin-bottom:15px;
        &::before{display:none;}
        p{color:$black;max-width:400px;}
      }
      
      .item{margin-right:0;}
      .mid,.right{float:left;margin-left:-2%;}
      .mid{width:40%;}
      .right{width:55%;padding-left:90px;padding-top:100px;
      }
    }

    @include bp(ns){padding:0px 0 100px;
      .medDef{max-width:650px;}
      p.secTitle,.left p{@include center-block();text-align:center}//;max-width:100%;}
      p.secTitle{font-size:38px;margin-bottom:5px;max-width:100%;}
      .left p{max-width:500px;line-height:1.4em;}

      p{font-size:18px;}
      .mid{margin-left:-6%;padding-top:110px;margin-top:60px;
        &::before{width:365px;height:365px;left:-30px;}
      }
      .right{margin-left:0;padding-top:40px;width:62%;padding-left:90px;padding-top:100px;
        &::before{width:480px;height:480px;top:0px;}
      }
    }

    @include bp(md){padding:15px 0 75px;
      .mid{margin-left:-5%;
        &::before{left:-25px;}
      }
    }

    @include bp(og){padding-bottom:30px;
      .medDef{max-width:450px;}

      p.secTitle{font-size:31px;}
      p{font-size:17px;}

      .mid,.right{width:100%;}
      .left{max-width:380px;margin:15px 0 10px 20px;padding:15px 0 0 20px;
        p{text-align:left;}
        &::before{display:block;width:70px;height:70px;right:0;background-color:rgba($plum,.2);}
      }
      .mid{max-width:380px;padding:10px 15px 10px 0px;margin-left:0;margin-top:0;float:right;
        &::before{width:85px;height:85px;left:0px;opacity:.2; left:auto;right:0;top:auto;bottom:-5px;}

        p{max-width:100%;color:$black;}
      }
      .right{max-width:450px;padding:80px 40px 35px;float:none;margin-top:20px;border-radius:50%;background-color:$peach;text-align:center;
        &::before{display:none;}
      }
      .item{@include center-block();max-width:300px;padding-bottom:20px;margin-bottom:0;position:relative;z-index:0;
        &::after{@include after();left:49.8%;width:2px;height:10px;bottom:10px;background-color:rgba($white,.5);}

        &:last-child::after{display:none;}
      }
    }
    
    @media (max-width:500px){padding-bottom:0px;
      p.secTitle{font-size:27px;}
      .left{margin-top:0px;padding-top:10px;}
      .right{max-width:100%;border-radius:0;padding:20px 0 0;margin-top:0;}
    }
  }
}
// background: linear-gradient(to bottom left, cyan 50%, palegoldenrod 50%);
@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.Websites{@include sec-default();
  .web2{padding:220px 0;
    .left,.right{position:relative;z-index:1;}
    .left{margin-left:-1%;}

    p.catL,p.catR{position:absolute;margin:0;line-height:1em;color:$color1;opacity:.2;}
    p.catL{@include rotate(-90deg);left:0;max-width:80px;bottom:35%;font-size:40px;}
    p.catR{@include rotate(90deg);right:0;margin-right:-180px;max-width:500px;bottom:47%;font-size:36px;}

    @media (min-width:1800px){.med2Def{max-width:1300px;}}
    @media (min-width:2200px){padding:260px 0;}
    @media (min-width:2500px){padding:290px 0;}


    @include bp(tw){padding:180px 0;
      p.catL,p.catR{opacity:.15;}
      p.catL{font-size:35px;max-width:50px;}
      p.catR{font-size:31px;margin-right:-160px;}

      .med2Def{max-width:900px;}
    }

    @include bp(tb){padding:160px 0;
      p.catL,p.catR{opacity:.1;font-weight:900;}
      
      .med2Def{max-width:850px;}
      .left{width:47%;margin-left:0;}
      .right{width:49%;padding-right:15px;}
      p{font-size:18px;}
    }

    @include bp(ns){padding:120px 0;
      p.catL,p.catR{display:none;}
      
      .med2Def{max-width:730px;}
      .left{width:48%;padding-right:10px;}
      .right{padding-right:0;}
      p{font-size:17px;}
    }

    @include bp(og){padding:100px 0;text-align:center;
      &::after{display:none;}
      .med2Def{max-width:600px;}
      .left,.right{@include sec-default();}
      .left{padding-right:0;margin-bottom:20px;}
    }
    @include bp(oc){padding:80px 0;}
  }

  // h1{@include sec-default();position:relative;overflow:hidden;
  //   span.noRw{margin-left:-20%;}
  // }
  // .rwWords{display:inline; padding-left:15px;overflow:hidden;
  //   span{width:100%;text-align:left;position:absolute;opacity:0;overflow:hidden;animation: rotateWordsSecond 9s linear infinite 0s; color:$color1-dk;}
  //   span.two{animation-delay:3s;}
  //   span.three{animation-delay:6s;}
  //   span.four{animation-delay:9s;}
  // }
  h1{@include sec-default();position:relative;
    br.dos{display:none;}

    @media (min-width:640px){overflow:hidden;
      span.noRw{margin-left:-20%;}
    }
    @include bp(oc){br.dos{display:block;}}
  }

  .rwWords{
    span{width:100%;color:$color1-dk;}
    br.set{display:none;}

    @media (min-width:640px){display:inline;padding-left:15px;overflow:hidden;
      span{text-align:left;position:absolute;opacity:0;overflow:hidden;animation: rotateWordsSecond 9s linear infinite 0s;}
      span.two{animation-delay:3s;}
      span.three{animation-delay:6s;}
      span.four{animation-delay:9s;}
    }

    // @include bp(oc){max-width:200px;@include center-block();line-height:1em;
    //   br.set{display:block;}
    //   span.one{float:left;text-align:left;}//margin-right:20px;}
    //   span.two{margin-left:20px;}
    //   span.three{margin-left:60px;}
    // }
  }



  @keyframes rotateWordsSecond {
    0% {opacity:1;animation-timing-function:ease-in;width:0px;}
    10% {opacity:1;width:100%;}
    20% {opacity:1;width:100%;}
    27% {opacity:0;width:100%;}
    100% {opacity:0;}
  }

  .recentSlides{position:relative;z-index:0;padding:30px 0 80px;    
    .instr{@include sec-default2();position:relative;max-width:250px;text-align:center;
      p{font-size:18px;letter-spacing:4px;text-transform:uppercase;margin-bottom:0;}
    }

    .intro{@include sec-default();margin-bottom:30px;}
    .itemWrap{width:80%;@include center-block();}
    .iwInner{@include sec-default();text-align:center;padding:50px 60px 50px 20px;}

    .slideNav{color:$black;margin-top:1px;
      svg{width:30px;height:30px;}
    }
    
    .hswItem{width:400px;margin-right:20px;box-shadow:0px 10px 20px rgba($black,.3);
      .frame{width:100%;height:550px;overflow:hidden;position:relative;}
      img{width:100%;height:auto;}

      &.mm img{bottom:-90px;}
      &.marc img{bottom:-515px;}
      &.mold img{bottom:-157px;}
      &.jcmf img{bottom:-520px;}
    }

    @media (min-width:640px){
      .hswItem{
        img{position:absolute;z-index:0; -webkit-transition: top 4s; -moz-transition: top 4s; -ms-transition: top 4s; -o-transition: top 4s;transition: bottom 4s;}
        &:not(.noScroll):not(.shortScroll) .frame:hover img{bottom:0;-webkit-transition: all 8s;-moz-transition: all 8s;-ms-transition: all 8s;-o-transition: all 8s;transition: all 8s;}
        &.shortScroll .frame:hover img, &.noScroll .frame:hover img{-webkit-transition: all 3s;-moz-transition: all 3s;-ms-transition: all 3s;-o-transition: all 3s;transition: all 3s;bottom:0;}
      }
    }
    @media (min-width:1800px){
      .itemWrap{width:100%;max-width:1700px;}//max-width:1700px;}
      .iwInner{padding:40px 20px;}
      .instr{display:none;}
    }

    @media (max-width:1600px){.itemWrap{width:85%;}}
    @include bp(tl){.intro{margin-bottom:10px;}}
    @include bp(tw){//padding-left:40px;
      .itemWrap{width:90%;}
      .iwInner{padding-bottom:30px;}
    }
    
    @include bp(tb){padding-bottom:30px;//padding:100px 0 30px 20px;
      .instr{max-width:230px;
        p{font-size:17px;}
        button{margin-top:1px;}
        svg{width:25px;height:25px;}
      }

      .iwInner{padding-top:30px;}

      .hswItem{width:350px;
        .frame{height:480px;}
        &.mm img{bottom:-79px;}
        &.marc img{bottom:-450px;}
        &.mold img{bottom:-137px;}
        &.jcmf img{bottom:-456px;}
      }
    }

    @media (max-width:959px){
      .iwInner{padding-top:20px;}
      .instr{max-width:200px;
        p{font-size:16px;letter-spacing:3px;}
      }
    }

    @include bp(og){//padding:40px 0 0px;
      .instr{max-width:100%;
        p{font-size:15px;}
      }
      .slideNav{
        &.arrowLeft{left:5%;}
        &.arrowRight{right:5%;}
      }
      .itemWrap{width:100%;}
    }

    @include bp(oc){.hswItem img{position:relative;bottom:auto !important;}}

    @include bp(xx){
      .slideNav{
        &.arrowLeft{left:3%;}
        &.arrowRight{right:3%;}
      }
    }
  }
}
$fa: #fafafa;
$f0: #f0f0f0;
$f2: #f2f2f2;
$f7: #f7f7f7;
$f9: #f9f9f9;
$df: #dfdfdf;
$white: #fff;
$black: #000;
$red: #ed1e24;
// $color1:#1a59e8;
$color1:#1C69DF;
$color2:#2aca9c;
$color3:#5a49e5; //#311cdf;
$color4:#df1c69;
$peach:#ff6f69;
$color1-lt:#e8f0fb;
$color1-dk:#13499b;
$silver:#F1F6FC;
$bsilver:#F1F9FF;
$blue:#1e87f0;
$imgBlue:#87a6d5;
$plum:#931cdf;
$bing:#008273;
$cronin:#30c4e3;
$barrow:#397eed;
$spear:#4ea4ab;
$zip:#51ac28;
$frank:#2c3c87;

$borderB: 1px solid rgba($black,.1);
$borderW: 1px solid rgba($white,.1);

$mul:'Mulish', sans-serif;
$gochi:'Gochi Hand', cursive;

$mont:'Montserrat', sans-serif;
// $robo: 'Roboto Flex', sans-serif;

$body: $mul;
$emph: $gochi;
$hd: $mont;
@import './variables.scss';
@import './mixins.scss';

.insideXl,.insideN,.inside,.insideAlt,.medDef,.med2Def,.med3Def,.insideNrw,.medkDef,.wrap18,.wrap20{@include center-block();}
.wrap20{max-width:2000px;}
.wrap18{max-width:1800px;}
.inside,.insideAlt,.insideXl,.insideN{max-width:1700px;}
.medDef,.med2Def,.med3Def,.insideNrw,.medkDef{width:90%;}
.insideXl{width:95%;}
.insideN{width:90%;}
.inside{width:85%;}
.insideAlt{width:80%;}
.medDef{max-width:1080px;}
.medkDef{max-width:1000px;}
.med2Def{max-width:940px;}
.med3Def{max-width:850px;}
.insideNrw{max-width:820px;}

.centerBlock{@include center-block();}
.bmargin0{margin-bottom:0;}
.bmargin05{margin-bottom:5px;}
.bmargin1{margin-bottom:10px;}
.tmargin0{margin-top:0;}
.tmargin2{margin-top:20px;}
.textCenter{text-align:center;}
.textLeft{text-align:left;}
.textRight{text-align:right;}
.tpadding4{padding-top:40px;}
.tpadding6{padding-top:60px;}

.spacer1{@include sec-default();height:10px;}
.spacer2{@include sec-default();height:20px;}
.spacer25{@include sec-default();height:25px;}
.spacer3{@include sec-default();height:30px;}

.up{text-transform:uppercase;}
.cap{text-transform:capitalize;}

p.cat,p.catB{text-transform:uppercase;letter-spacing:2px;font-weight:800;}
p.cat{margin-bottom:0;}
p.catB{position:relative;z-index:0;padding-bottom:2px;font-size:.95em;@include stackA();
  &::after{left:0;border-bottom:1px solid rgba($white,.2);}
}
p.h3{font-family:$emph;font-size:27px;}
p.secTitle{font-family:$emph;font-size:35px;}
p.bold{font-weight:bold;}

hr{border-color:rgba($black,.1);
	&.hlf{width:50%;}
	&.less{width:25%;}
}
.clearfix::after, .clearfixBtn::after{content: "";clear: both;display: table;}
.clearfix.btn, .clearfixBtn {@include sec-default();margin-top:20px;}

.btnRow{@include sec-default();margin-top:15px;
  a{margin-right:15px;}
  a:last-child{margin-right:0px;}
}
.hsLink, .hsHover{@include button($color1, $white);}
.hsLinkRev{@include button($white, $color1);text-shadow:none;
  &::after{background-image:linear-gradient(90deg, transparent, rgba($color1, 0.25), transparent);}
}

.hsHover{position:relative;display:inline-block;
  &::before{@include before();z-index:1;
    background-image:url('../assets/images/arrow.png');background-size:40px;background-position: right center; background-repeat: no-repeat;display:none;
  }
  &:hover{padding-right:50px;transition: all .5s ease;}
  &:hover::before{display: block;transition: all 2s;}
  &:active::before{transition-delay:1s;}
}
.btnIcon{width:15px;display:inline;margin-left:5px;}

.textLink{font-weight:600;color:$color2;
  &.und{text-decoration:underline;}
  &:hover{color:darken($color2,15%) !important; opacity:1;}
}

.visuallyHidden{@include visuallyHidden();}
ul.disc li{list-style-type:disc;}

.flexRow{@include flex-default();}
.fullRow,.kpGrid{@include sec-default();
  // .flexWrap{display:flex;align-items:center;justify-content:space-around;}
  .hlf,.hlf1,.hlf2,.col,.left,.right{display:inline-block;vertical-align:top;}
  .hlf1,.col1,.left{float:left;}
  .hlf2,.col3,.right{float:right;}
  .left.pull{margin-left:-3%;}
  .right.pull{margin-right:-3%;}
  .left.push{margin-left:3%;}
  .right.push{margin-right:3%;}
  .mid3{margin-left:5.5%;}

  .cblock{@include center-block();}
  .grid5{width:5%;}
  .grid15{width:15%;}
  .grid20{width:20%;}
  .grid21{width:21%;}
  .grid22{width:22%;}
  .grid23{width:23%;}
  .grid24{width:24%;}
  .grid25{width:25%;}
  .grid26{width:26%;}
  .grid27{width:27%;}
  .grid28{width:28%;}
  .grid29{width:29%;}
  .grid30{width:30%;}
  .grid31{width:31%;}
  .grid32{width:32%;}
  .grid33{width:33%;}
  .grid34{width:34%;}
  .grid35{width:35%;}
  .grid36{width:36%;}
  .grid37{width:37%;}
  .grid38{width:38%;}
  .grid40{width:40%;}
  .grid41{width:41%;}
  .grid42{width:42%;}
  .grid43{width:43%;}
  .grid44{width:44%;}
  .grid45{width:45%;}
  .grid46{width:46%;}
  .grid47{width:47%;}
  .grid48{width:48%;}
  .grid49{width:49%;}
  .grid50{width:50%;}
  .grid51{width:51%;}
  .grid52{width:52%;}
  .grid53{width:53%;}
  .grid54{width:54%;}
  .grid55{width:55%;}
  .grid56{width:56%;}
  .grid57{width:57%;}
  .grid58{width:58%;}
  .grid59{width:59%;}
  .grid60{width:60%;}
  .grid62{width:62%;}
  .grid63{width:63%;}
  .grid64{width:64%;}
  .grid65{width:65%;}
  .grid66{width:66%;}
  .grid67{width:67%;}
  .grid68{width:68%;}
  .grid69{width:69%;}
  .grid70{width:70%;}
  .grid72{width:72%;}
  .grid73{width:73%;}
  .grid75{width:75%;}
  .grid80{width:80%;}
  .grid82{width:82%;}
  .grid85{width:85%;}
  .grid90{width:90%;}
  .grid100{@include sec-default();}


  // *** KPKP - DO WE WANT TO BE SO STRICT HERE ?
  @include bp(br){
    .medDef.mediaStack{max-width:680px;}
    .medDef.mediaStack .left, .medDef.mediaStack .right{width:100% !important;}
  }
}



.csDiv,.csDivFlip{position:absolute;left:0;width:100%;z-index:0;overflow:hidden;
  path{fill:$white;}
  &.f9 path{fill:$f9;}
}
.csDiv{top:0;}
.csDivFlip{bottom:0;@include rotate(180deg);}
@include bp(xx){
  .csDiv{top:-3px;}
  .csDivFlip{bottom:-3px;}
}


.simpleHeader{@include sec-default();padding-top:130px;position:relative;z-index:1;text-align:center;
  h1{font-size:60px;font-family:$emph;}//font-size:50px;font-family:$hd;}
  h2{letter-spacing:10px;text-transform:uppercase;margin:0;font-size:23px;}

  &.blob::before{@include before();background-repeat:no-repeat;background-position:bottom center;background-size:350px;background-image:url('./images/blobs.png');opacity:.6;}
 
  @media (min-width:1800px){padding-top:60px;}

  @include bp(tw){padding-top:90px;
    h1{font-size:55px;}
  }

  @include bp(tb){&.blob::before{background-size:330px;}}
  
  @include bp(ns){padding-top:20px;
    h1{font-size:45px;}
  }
  @include bp(tn){padding-top:0px;}
  @include bp(og){h1{font-size:35px;}}
  @include bp(oc){h1{font-size:28px;line-height:1.1em;}}

  &.websites{padding-bottom:70px;
    &::before{top:40px;}
    
    @media (min-width:1800px){padding-top:120px;padding-bottom:50px;margin-top:-70px;
      &::before{height:140%;top:-30px;}
    }
    @include bp(tw){padding-top:140px;}
    @include bp(ns){padding-top:20px;padding-bottom:0; &::before{background-size:170px;top:0;height:130%;background-position:top center;}}
    @include bp(og){margin-top:-15px;z-index:2; &::before{background-size:150px;height:130%;}}
    @include bp(oc){
      &::before{display:none;}
      h1{margin-bottom:0;}
    }
  }

  &.sem{padding-bottom:60px;
    &::before{top:50px;}
    .med2Def{max-width:920px;}
    h1{margin-bottom:0;font-size:64px;}
    p{letter-spacing:4px;text-transform:uppercase;margin:0;font-size:20px;}


    @media (min-width:1800px){padding:40px 0 20px;
      &::before{height:140%;top:0;}
      h1{margin-top:15px;}
    }
    @include bp(tw){padding-top:120px;
      h1{font-size:60px;}
      p{font-size:19px;}
    }

    @include bp(tb){padding-top:100px;}
    @include bp(ns){padding-top:0px;padding-bottom:0;
      &::before{display:none;}
      // &::before{top:0px;height:140%;background-size:185px;background-position:top center;}
      h1{font-size:45px;}
      p{font-size:20px;letter-spacing:0px;text-transform:none;}
    }
    
    @include bp(md){
      h1{font-size:41px;}
      p{font-size:19px;}
    }
    @include bp(og){
      h1{font-size:35px;}
      p{font-size:16px;}
    }

    @media (max-width:500px){h1{font-size:27px; br{display:none;}}}
  }

  &.about{
    h1{font-size:55px;}
    p{@include center-block();width:90%;}

    .linkRow{@include sec-default();margin-top:10px;
      span,a{color:$color2;margin:0 15px;font-size:20px;}
      br, .clearfix{display:none;}
    }

    @include bp(tl){margin-top:-50px;}

    @include bp(tw){
      .med2Def{max-width:870px;}
      h1 br{display:none;}
    }

    @include bp(tb){
      h1{font-size:50px;
        br{display:block;}
      }
      .linkRow{
        span,a{margin:0 10px;}
      }
    }

    @include bp(ns){margin-top:0px;
      .med2Def{max-width:720px;}
      h1{font-size:40px;}
      .linkRow{
        span,a{font-size:18px;}
      }
    }

    @include bp(tn){
      .med2Def{max-width:630px;}
      h1{font-size:35px;}
      .linkRow{margin-top:5px;
        span,a{font-size:17px;}
      }
    }

    @include bp(og){
      .med2Def{max-width:540px;}
      h1{font-size:31px;
        br{display:none;}
      }
      .linkRow{margin-top:0;}
    }

    @include bp(oc){padding-top:10px;
      h1{font-size:28px;}
      .linkRow{line-height:1.2em;
        span, a{font-size:16px;}
        br.brSep{display:block;}
      }
    }

    @include bp(ph){padding-top:15px; 
      h1{font-size:26px;}
      
    }

    @media (max-width:450px){.linkRow .clearfix{display:block;margin-bottom:5px;}}
  }

  &.ecom{margin-bottom:50px;padding-top:70px;
    .ilShop{width:160px;display:inline;margin-top:4px;}
    .logo{width:220px;@include center-block();margin-top:15px;}

    @media (min-width:1800px){margin-bottom:0;}
    @include bp(tl){padding-top:40px;}
    @include bp(tw){padding-top:10px;}
    @include bp(tb){margin-bottom:35px;
      .med2Def{max-width:800px;}
    }
    @include bp(ns){padding-top:0;
      .med2Def{max-width:730px;}
      h1{margin-bottom:0;}
      .ilShop{width:130px;}
      p{font-size:18px;}
      .logo{width:200px;margin-top:10px;}
    }
    @include bp(og){margin-bottom:0;margin-top:-20px;z-index:3;
      .ilShop{width:100px;}
      p{font-size:17px;}
      .logo{width:180px;}
    }
    @include bp(oc){margin-top:0px;
      .ilShop{width:80px;}
      p{font-size:16px;}
    }
  }
}  


.sec2Shape{background-color:$color1-lt;@include stackA();
  &::after{width:50%;right:0;background-color:$silver;}

  p:last-child{margin-bottom:0;}
}
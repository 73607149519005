@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;500;700;800;900&family=Gochi+Hand&family=Montserrat:wght@100;200;500;800&display=swap');
// &family=Roboto+Flex:opsz,wght@8..144,200;8..144,600;8..144,800
@import "./assets/global";
@import "./assets/variables";
@import "./assets/mixins";

*, *::before, *::after{box-sizing:border-box;}
body{margin:0;}

html{
  // h1, h2, h3, h4, h5, p, li{color:$black;line-height:1.5em;font-family:$body;margin:0 0 10px;}
  &, h1, h2, h3, h4, h5, p, li{color:$black;line-height:1.5em;font-family:$body;font-weight:300;}
  h1, h2, h3, h4, h5, p, li{margin:0 0 10px;}
  h1,h2{line-height:1.2em;}
  &,p{font-size:19px;}
  // a,span{color:inherit;font-weight:inherit;font-family:inherit;}
  
  h1{font-size:40px;font-weight:900;}
  h2{font-size:30px;}
  h3{font-size:25px;}

  @include bp(tb) {
    h1{font-size:40px;}
    h2{font-size:25px;}
    h3{font-size:21px;}
  }
}

a,button{text-decoration:none;
  &:hover{opacity:.7;text-decoration:none;@include ease(.3s);}
}
button{background:none;border:none;border-radius:0;color:inherit;font-weight:inherit;font-family:inherit;
  &:hover{cursor:pointer;}
}
img{max-width:100%;height:auto;display:block;}
ul{margin:0;list-style-type:none;padding-left:0;}
li{list-style-type:none;}

.hsWrap{@include sec-default();position:relative;}
// .hsWrap.shopify footer,.hsWrap.ecommerce footer{background-color:$f9;}
.Content{@include sec-default();
  @media (min-width:992px){min-height:55vh;}
  @media (min-width:1024px){min-height:82vh;}
}

.modalExt{
  .modalDialog{width:700px;}

  p.title{margin-bottom:0;font-weight:800;text-transform:uppercase;letter-spacing:2px;}
  hr{margin-top:0;}

  .modalContent p{@include sec-default();margin-bottom:25px;font-size:18px;
    a{color:darken($color2,7%);}
  }

  @include bp(og){
    .modalDialog{width:90%;padding-bottom:10px;}
    
    p.title{letter-spacing:1px;font-size:17px;}
    .modalContent p{font-size:17px;}
    .col{width:100%;}
  }

  @media (max-width:500px){p.title{font-size:16px;letter-spacing:.5px;}}
}

.ToTopBtn{
  svg{margin-bottom:4px;}
  @include bp(tl) {bottom:50px !important;right:15px !important;}
  @include bp(br) {bottom:15px !important;background-color:$color1 !important;}
  // @include bp(oc) {bottom:25px !important;}
}
// background: linear-gradient(to bottom left, cyan 50%, palegoldenrod 50%);
@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.CompanyPage{@include sec-default();
  .comp2{padding:190px 0; margin:70px 0 30px; 
    &::before{@include before();width:50%;max-width:580px;left:12%;@include sec-bgimage('./images/homeAreas5.png');z-index:1;}

    .col{position:relative;z-index:1;}
    .right{width:53%;margin-right:-9%;}

    p.cat{font-size:17px;margin-bottom:5px;
      br{display:none;}
    }

    @media (min-width:980px){&::before{@include rotate(7deg);}}
    @media (max-width:1500px){&::before{left:5%;top:-10px;}}
    
    @media (min-width:1800px){margin:0;padding:150px 0;
      &::before{left:16%;}
      .right{width:62%;margin-right:-18%;margin-top:70px;margin-bottom:70px;}
    }

    @include bp(tw){padding:160px 0;margin-bottom:0;
      &::before{left:1%;top:0px;max-width:100%;width:45%;}
      
      .right{width:44%;margin-right:0;}
      p.cat br{display:block;}
    }

    @include bp(ns){padding:130px 0;margin-top:15px;text-align:center;
      &::after{display:none;}
      &::before{width:97%;height:90%;background-size:150px;background-position:bottom right;z-index:0;}

      .med2Def{max-width:550px;}
      .right{width:100%;}

      p.cat br{display:none;}
    }
    
    @include bp(tn){margin-top:5px;padding:110px 0 100px;}

    @include bp(og){padding:100px 0;
      &::before{display:none;}
      p:not(.cat){font-size:17px;}
      p.cat{font-size:16px;}
    }
    @include bp(oc){padding:95px 0;}
    
    @include bp(ph){padding:80px 0; p.cat{@include center-block();max-width:300px;}}
  }

  .comp3{position:relative;padding-bottom:100px;
    .bgRow{@include sec-default();position:relative;z-index:0;padding:40px 0 120px;margin-bottom:60px;
      &::after{@include after();width:55%;right:-7%; @include sec-bgimage('./images/homeAreas1.png');background-position:center right;}
    }
    .left{margin-left:-5%;}
    .tspace{margin-top:6%;}
    .review{width:80%;@include center-block();}

    p.quoteB{font-weight:500;margin-top:20px;font-style: italic;}
    p.tes{font-size:21px;}
    p.name{font-size:16px;font-style:italic;}

    @include bp(tx){
      .bgRow::after{width:50%;right:-4%;}
      .left{margin-left:-3%;}
    }

    @include bp(tw){padding-bottom:50px;
      .bgRow{padding:0px 0 100px;margin-bottom:30px;&::after{width:55%;right:0;}}
      .left{width:40%;margin-left:0;}
      .review{width:100%;}
    }

    @include bp(tb){padding-bottom:30px;
      .bgRow{padding-bottom:50px;
        &::after{width:50%;right:-2%;bottom:20px;}
      }
      .left{width:48%;}
      .tspace{margin-top:5%;}

      p.tes{font-size:19px;}
      p.name{font-size:15px;}
    }

    @include bp(ns){margin-top:-20px;padding-bottom:15px;
      .bgRow{padding-bottom:110px;
        &::after{width:100%;left:0;right:auto;bottom:-25px;background-size:200px;background-position:bottom center;}
      }
      .left{width:90%;max-width:650px;@include center-block();text-align:center;
        p{font-size:18px;}
      }
      .review{margin-top:20px;}
      p.tes{font-size:18px;color:$color1-dk;}
      p.name{font-size:14px;color:$color1;}
    }

    @include bp(oc){margin-top:-10px;padding-bottom:0px;
      .bgRow{padding-bottom:0px;
        &::after{display:none;}
      }
      .left{width:95%;
        p{font-size:17px;}
      }
      .review{margin-top:0px;text-align:center;}
      p.tes{font-size:17px;}
      p.name{margin-bottom:0;}
    }

    @include bp(ph){margin-top:0px;}
  }
}  
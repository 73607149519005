// background: linear-gradient(to bottom left, cyan 50%, palegoldenrod 50%);
@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";


// https://codepen.io/georgedoescode/full/dyNVNjG
.HomePage{@include sec-default();position:relative;z-index: 0;
  .tabNav{
    &.centerNav{align-items:center;justify-content:center;}
    &::before{display:none;}

    li{padding:0;margin:0 5px;}
  }

  $homeTheme: $color1-lt;
  .homeSec1.v3{@include sec-default();position:relative;z-index:1;
    .hs1Top{@include sec-default();position:relative;z-index:0;background-color:$white;min-height:300px;margin-top:40px;margin-bottom:40px;padding-bottom:100px;
      &::before, &::after{@include after();left:0;height:120%;bottom:-20%;}
      &::before{@include sec-bgimage2();background-image:url('../../assets/images/city.png');background-position:bottom center;}
      &::after{background-color:rgba($white,.95);}
    }

    // .image{position:relative;z-index:0;width:95%;@include center-block();}
    .text{text-align:center;position:absolute;z-index:1;left:0;width:100%;top:30%;}
    .innerText{max-width:600px;@include center-block();}
    h1{margin-bottom:10px;}
    h2{line-height:1.3em;}

    .tabNav{
      li:nth-child(2) .hsLink{background-color:$color1-dk;}
      button.hsLink:hover, .hsLink:hover{color:$white !important;}
      li.iconBtn .hsLink{padding:8px;}
      .hsLink{color:$white;font-size:15px;padding:10px 20px;}
    }


    .imageGroup{width:40%;margin-top:40px;}
    .img1{max-width:450px; max-width:420px; float:left;margin-left:3%;}
    .img2{max-width:370px; max-width:350px; float:right;margin-right:3%;}


    @media (max-width:1550px){
      .text{top:25%;}
      .img1{max-width:375px;}
      .img2{max-width:300px;}
    }
    @media (max-width:1450px){.hs1Top{margin-top:20px;}}
    // @media (max-width:1450px){.image{width:98%;}}
    @include bp(tl){
      .hs1Top{margin-top:0px;padding-bottom:80px;}
      .img1{max-width:355px;}
      .img2{max-width:280px;}
    }
    @include bp(tx){
      .hs1Top{min-height:100%;padding:30px 20px 70px;}
      .text{padding:100px 0 160px;top:8%;}
      // .text{position:relative;padding:100px 0 160px;top:auto;background-image:url('../../assets/images/homeBg1.png'), url('../../assets/images/homeBg2.png');
      //   background-size:23%, 21%;background-position: left center, right center;background-repeat:no-repeat, no-repeat;
      // }
      // .image{display:none;}
      .img1{margin-left:0;max-width:320px;}
      .img2{margin-right:0;max-width:250px;}
    }
    @include bp(tw){
      .hs1Top{padding-top:0;}//padding-bottom:20px;}
      // .text{padding-bottom:190px;background-size:25%, 22%;}
      h1{font-size:36px;}
      h2{font-size:26px;}

      .tabNav{margin-left:0;
        button, .hsLink{font-size:14px;}
      }
      
      .text{top:0;}//padding:100px 0 160px;}
      .img1{max-width:270px;}
      .img2{max-width:200px;margin-right:1%;}
    }
    @include bp(tb){
      .hs1Top{padding-left:5px;padding-right:5px;}
      .text{padding:80px 0 130px;}//background-size:24%, 21%;}
      .tabNav{
        button,.hsLink{letter-spacing:1px;padding:10px 15px 11px;}
      }

      h1{font-size:33px;}
      h2{font-size:23px;}
    }
    @include bp(br){
      .hs1Top{margin-bottom:20px;}
      .text{padding:30px 0 0px;}//background-size:21%, 18%;}
      .img1{max-width:200px;margin-left:10px;margin-top:20px;}
      .img2{max-width:150px;margin-right:10px;margin-top:10px;}

      h1{font-size:30px;}
      h2{font-size:21px;}
    }
    @include bp(tn){
      .text{padding-top:15px;}
      .img1{max-width:170px;}
      .img2{max-width:130px;}

      h1{font-size:27px;}
      h2{font-size:19px;}
    }

    @include bp(og){
      .text{padding:0px;position:relative;}//background-image:none;}
      .hs1Top{padding-left:0;padding-right:0;
        &::after{opacity:.8;}
      }
      h1{font-size:25px;}
      h2{font-size:17px;}

      .btnRow{margin-top:10px;}
      .tabNav{
        li{margin:5px 2px;}
        button,.hsLink{padding:10px;letter-spacing:.5px;}
        li.iconBtn .hsLink{padding:7px 8px;}
      }

      .imageGroup{display:none;}
    }

    @include bp(oc){
      .hs1Top{padding-bottom:50px;}
      .text{padding-top:15px;}
    }

    @media (max-width:500px){
      .tabNav{
        li{max-width:135px;}
        li.iconBtn .hsLink{padding:21px 8px;}

        .hsLink{line-height:1.5em;}
      }
    }
  }

  .homeSec2{@include sec-default();position:relative;z-index:0;
    .hs2Wrap{width:85%;}
    .hs2Inner{@include sec-default();background-color:$color1;padding:70px 50px;}
    .tabContent{max-width:870px;@include center-block();}

    .hs2Inner{padding:70px 50px;position:relative;
      &::before{@include before();top:-80px;@include sec-bgimage('./images/layer1.png');background-position:top left;}
      &::after{@include after();bottom:-80px;left:0;@include sec-bgimage('./images/layer2.png');background-position:bottom left;}
    }

    p{color:$white;}
    p:not(.h3):not(.catB){color:$white;font-size:20px;}
    .hsLinkRev{font-size:14px;padding-top:8px;padding-bottom:8px;}
    .subTc p.catB{font-size:20px;}
    .textLink{color:lighten($color2,10%);
      &:hover{color:inherit !important;opacity:.5;}
    }


    @media (min-width:1800px){
      .hs2Inner{padding-top:50px;padding-bottom:50px;
        &::before{top:-100px;}
        &::after{bottom:-100px;}
      }
    }

    @media (min-width:2250px){
      .hs2Inner{
        &::before{top:-130px;}
        &::after{bottom:-130px;}
      }
    }
    
    @media (max-width:1550px){
      .hs2Inner{
        &::before{top:-70px;}
        &::after{bottom:-70px;}
      }
    }

    @include bp(tw){
      p:not(.h3):not(.catB){font-size:19px;}
      .hs2Inner{padding:50px;}
 
      .subTc p.catB{font-size:19px;}
    }
    @include bp(tb){
      .hs2Wrap{width:90%;}

      .hs2Inner{
        &::before{top:-55px;}
        &::after{bottom:-55px;}
      }
    }
    @include bp(br){
      p:not(.h3):not(.catB){font-size:17px;}
      .subTc p.catB{font-size:17px;}

      .hs2Inner::after{bottom:-45px;}
    }
    @media (max-width:959px){
      .hs2Wrap{width:92%;}
      .hs2Inner{padding:30px 40px;}
    }
    @include bp(og){
      .hs2Wrap{width:95%;}
      .hs2Inner{padding:15px 30px;}
    }

    @include bp(oc){
      .hs2Inner{
        &::before{top:-40px;}
        &::after{bottom:-40px;}
      }
    }

    @include bp(sm){
      .hs2Inner{
        &::before{top:-25px;}
        &::after{bottom:-25px;}
      }
    }
  }

  .recentSlides{position:relative;z-index:0;padding:130px 0 0px;
    &::before,&::after{@include before();top:-15.5%;height:63%;width:85%;left:auto;right:0;}
    &::before{background-color:rgba($homeTheme,.92);}
    &::after{z-index:-2;opacity:0.6; background-color:$color1-lt;}
    
    .titleBtn, .itemWrap{display:inline-block;}
    .titleBtn{float:left;width:25%;margin-top:12%;position:relative;z-index:0;
      .tbWrap{width:99%;max-width:220px;float:right;}
      p.combo{text-transform:uppercase;margin-bottom:5px;font-weight:300;}
      span.press{font-size:35px;letter-spacing:18px;}
      span.spread{font-size:75px;line-height:1.1em;letter-spacing:15px;font-weight:bold;}

      .undLink{@include sec-default();font-size:17px;text-transform:uppercase;letter-spacing:8px;font-weight:bold;padding-left:5px;
        img{margin-top:-2px;margin-left:0;}
      }
    }

    .itemWrap{width:70%;float:right;max-width:1150px;overflow:hidden; padding:30px 20px 50px 50px;}
   
    .instr{@include sec-default2();position:relative;margin-top:40px;max-width:250px;text-align:center;
      p{font-size:18px;letter-spacing:4px;text-transform:uppercase;margin-bottom:0;}
    }
    .instr.mobile{display:none;}
    .slideNav{color:$black;
      svg{width:30px;height:30px;}
      &.arrowLeft{left:-5px;}
    }
    
    .hswItem{width:400px;margin-right:30px;box-shadow:0px 10px 30px rgba($black,.3);
      .frame{width:100%;height:550px;overflow:hidden;position:relative;}
      img{width:100%;height:auto;}//position:absolute;z-index:0; -webkit-transition: top 4s; -moz-transition: top 4s; -ms-transition: top 4s; -o-transition: top 4s;transition: bottom 4s;}
      // &:not(.noScroll):not(.shortScroll) .frame:hover img{bottom:0;-webkit-transition: all 8s;-moz-transition: all 8s;-ms-transition: all 8s;-o-transition: all 8s;transition: all 8s;}
      // &.shortScroll .frame:hover img, &.noScroll .frame:hover img{-webkit-transition: all 3s;-moz-transition: all 3s;-ms-transition: all 3s;-o-transition: all 3s;transition: all 3s;bottom:0;}
    
      &.agam img{bottom:-205px;}
      &.bif img{bottom:-625px;}
      &.flag img{bottom:-195px;}
      &.blaze img{bottom:-433px;}
    }

    @media (min-width:640px){
      .hswItem{
        img{position:absolute;z-index:0; -webkit-transition: top 4s; -moz-transition: top 4s; -ms-transition: top 4s; -o-transition: top 4s;transition: bottom 4s;}
        &:not(.noScroll):not(.shortScroll) .frame:hover img{bottom:0;-webkit-transition: all 8s;-moz-transition: all 8s;-ms-transition: all 8s;-o-transition: all 8s;transition: all 8s;}
        &.shortScroll .frame:hover img, &.noScroll .frame:hover img{-webkit-transition: all 3s;-moz-transition: all 3s;-ms-transition: all 3s;-o-transition: all 3s;transition: all 3s;bottom:0;}
      }
    }

    @media (min-width:1800px){padding-top:160px;
      .customWrap{float:right;max-width:1900px;}
      .titleBtn{width:20%;max-width:330px;
        .tbWrap{float:none;}
      }
      .itemWrap{width:80%;max-width:1350px}//1150px;}
    }

    @include bp(tl){padding-bottom:30px; .itemWrap{width:72%;}}
    @include bp(tw){padding-left:40px;
      .titleBtn{max-width:250px;margin-top:15%;
        span.press{font-size:30px;}
        span.spread{font-size:65px;}
      }
      .itemWrap{width:74%;}
    }
    
    @include bp(tb){padding:100px 0 30px 20px;
      &::before,&::after{width:90%;height:70%;}

      .titleBtn{
        span.press{font-size:25px;padding-left:3px;}
        span.spread{font-size:60px;}
      }

      .instr{max-width:230px;
        p{font-size:17px;}
        button{margin-top:1px;}
        svg{width:25px;height:25px;}
      }

      .hswItem{width:350px;
        .frame{height:480px;}
        &.bif img{bottom:-550px;}
        &.agam img{bottom:-185px;}
        &.flag img{bottom:-155px;}
        &.blaze img{bottom:-378px;}
        // &.mm img{bottom:-80px;} 
      }
    }

    @media (max-width:959px){padding:80px 0 0px 30px;      
      .titleBtn{max-width:200px;text-align:right;
        span.press{letter-spacing:13px;font-size:23px;padding-left:0;}
        span.spread{letter-spacing:10px;font-size:54px;}
      }
      .instr{margin-top:25px;max-width:200px;
        p{font-size:16px;letter-spacing:3px;}
      }
    }
    @include bp(md){&::before,&::after{height:65%;}}
    
    @include bp(og){padding:40px 0 0px;
      &::before,&::after{width:100%;}

      .titleBtn{@include sec-default();max-width:100%;text-align:center;margin:70px 0 0px;
        .tbWrap{max-width:100%;}
        br{display:none;}
        p{margin-bottom:0;}
        span.press,span.spread{letter-spacing:5px;font-size:25px;}
      }
      
      .instr{max-width:180px;margin-top:5px;margin-bottom:10px;
        p{font-size:15px;font-weight:bold;}
        // button{margin-top:1px;}
        svg{width:20px;height:20px;}
      }
      .instr.fw{display:none;}
      .instr.mobile{@include center-block();}


      .itemWrap{width:100%;padding-top:15px;}
      .hswItem{margin-right:20px;}
    }

    @include bp(oc){
      .titleBtn{margin:30px 0 0;
        span.press, span.spread{font-size:22px;}
      }
      .instr{@include center-block();}
      .itemWrap{padding-bottom:30px;}

      .hswItem{//box-shadow: none;
        // .frame{height:auto;box-shadow:0px 10px 30px rgba($black,.3);}//overflow:hidden;}
        img{position:relative;bottom:auto !important;}
      }
    }

    // @include bp(sm){
    //   .itemWrap{padding:10px 15px 30px 20px;}
    //   .hswItem{width:300px;
    //     .frame{height:430px;}
    //     &.bif img{bottom:-452px;}
    //     &.agam img{bottom:-138px;}
    //     &.flag img{bottom:-130px;}
    //     &.blaze img{bottom:-305px;}
    //     // &.mm img{bottom:-80px;} 
    //   }
    // }

    @include bp(xx){
      .titleBtn{
        span.press, span.spread{font-size:20px;}
      }
    }
  }

  .homeSecReviews{@include sec-default();position:relative;z-index:0;padding:70px 0 20px;
    .medDef{max-width:1200px;position:relative;z-index:1;}

    .review{display:inline-block;margin-bottom:60px;text-align:justify;margin-top:40px;position:relative;}
    // .revOne::after,.revLast::after{@include abs();height:70%;@include sec-bgimage('./images/blueSq.png');}
    .revOne{width:60%;float:left;
      // &::after{width:30%;left:-15%;top:-30px;}
    }
    .revMid{width:40%;max-width:394px;float:right;}
    .revLast{width:46%;margin-left:10%;//width:55%;margin-left:5%;margin-top:60px;}
      // &::after{width:35%;right:-10%;bottom:-20px;}
    }
    .imageBox{width:38%;float:left;margin-left:-4%;margin-top:-20px;}


    .titleBoxAbs{position:absolute;z-index:-1;width:100%;left:0;text-align:center;top:-25px;
      p{text-transform:uppercase;margin-bottom:0;font-weight:300;font-size:80px;letter-spacing:10px;font-weight:900;
        color:$color1-lt;opacity:.8;//color:rgba($peach,.12);
      }
    }

    p.name{font-size:15px;font-style:italic;}
    .clearfix.rules{display:none;}


    @include bp(tl){padding-top:40px;}
    @include bp(tw){
      .titleBoxAbs{top:-35px;
        p{font-size:70px;letter-spacing:10px;}
      }

      .medDef{max-width:990px;}

      .review{margin-top:20px;margin-bottom:40px;}
      .revMid{width:34%;}
      .revLast{width:55%;margin-left:6%;}
      .imageBox{width:35%;margin-left:0;margin-top:-10px;}
    
      .partnerRow{margin-top:-20px;}
    }
    @include bp(tb){margin-top:30px;padding-top:0px;
      .titleBoxAbs{top:-35px;
        p{font-size:65px;}
      }

      .revOne{width:61%;}
      .revMid{width:35%;}
      .revLast{width:60%;margin-left:3%;margin-top:0px;}
      .imageBox{width:33%;}
      .revLast,.imageBox{margin-bottom:20px;}
    
      .clearfix.rules{display:block;}
      .partnerRow{margin-top:0px;width:100%;@include center-block();text-align:center;}
    }

    @include bp(br){
      .titleBoxAbs{top:-30px;
        p{font-size:60px;}
      }

      .medDef{max-width:760px;}
     .review  p:not(.name){font-size:17px;}
      .imageBox{width:30%;margin-bottom:0;margin-top:0;}
    }

    @media (max-width:959px){padding-bottom:0;
      .titleBoxAbs{top:-10px;
        p{font-size:40px;letter-spacing:5px;}
      }
      
      .review{margin-bottom:20px; text-align:left;}
      .imageBox{width:25%;margin-top:30px;}
      .revLast{width:72%;float:right;margin-left:0;}
    }

    @include bp(tn){.revMid{width:36%;}}

    @include bp(og){display:none;
      .titleBoxAbs{top:auto;position:relative;margin-bottom:5px;
        p{font-size:20px;letter-spacing:3px;color:$color1;}
      }
      .review{margin-top:0px;margin-bottom:15px;}
      .revOne,.revMid,.revLast{width:100%;}
      .revMid{max-width:100%;text-align:right;}
      .imageBox{display:none;}
      .review p:not(.name){font-size:16px;margin-bottom:7px;}
      p.name{font-size:14px;}
    }
  }


  .reviewsMob{display:none;text-align:center;padding-top:20px;
    .hsrSec,.hsrWrap,.hsrList{width:100%;}

    .titleBoxAbs{margin-bottom:5px;text-align:center;
      p.one{font-size:20px;letter-spacing:3px;text-transform:uppercase;margin-bottom:0;font-weight:900;color:$color1;opacity:.8;}
    }
    .rNav{max-width:130px;@include center-block();position:relative;
      p{font-size:14px;font-weight:400;color:#717171;}
    }
    .slideNav{color:$black;margin-top:1px;
      svg{width:18px;height:18px;}
      &.arrowLeft{left:-4px;}
    }

    .insideN{max-width:600px;}
    .hsrItem p:not(.name){font-size:16px;margin-bottom:7px;font-weight:normal;}
    p.name{font-size:14px;font-weight:300;color:#717171;font-style:italic;}

    @include bp(og){@include sec-default();}
  }
}
// background: linear-gradient(to bottom left, cyan 50%, palegoldenrod 50%);
@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.ShopifyPage, .EcomPage{@include sec-default();
  .ecom2{padding:190px 0;
    .imgTwist{width:46%;max-width:550px;bottom:67px;left:50px;@include rotate(-11deg);position:absolute;z-index:1;}

    p.catR{position:absolute;font-size:35px;margin:0 -130px 0 0;max-width:500px;bottom:47%;right:0;
      line-height:1em;color:$color1;font-weight:bold;font-family:$mont;opacity:.2;@include rotate(90deg);
    }

    .right{position:relative;z-index:1;}
    li{font-size:20px;padding-left:17px;@include stackB();
      &::before{content:"√";font-size:15px;font-weight:bold;top:2px;color:$color1;}
    }
    li:last-child{margin-bottom:0;}

    .csDivFlip{z-index:2;}


    @media (min-width:2000px){padding:220px 0;}
    @media (min-width:2200px){padding:240px 0;}
    @media (min-width:2600px){padding:270px 0;}
    @include bp(tw){padding:160px 0; 
      .imgTwist{bottom:55px;left:15px;}
      .right{margin-right:6%;}
    }
    @include bp(tb){padding:140px 0;
      .imgTwist{bottom:50px;left:5px;}
    }
    @include bp(ns){padding:125px 0;
      .imgTwist{bottom:45px;}
      p.catR{font-size:32px;margin-right:-120px;opacity:.15;}
    }
    @include bp(tn){padding:100px 0;
      .imgTwist{bottom:40px;left:0;}
      p.catR{font-size:30px;margin-right:-115px;bottom:45%;}
      li{font-size:19px;}
      .right{margin-right:5%;}
    }
    @include bp(md){.imgTwist{bottom:35px;}}
    @include bp(og){
      li{font-size:18px;}
      p.catR{font-size:25px;margin-right:-93px;}
      .right{width:47%;margin-right:0;}
    }

    @include bp(oc){padding:80px 0;
      &::after{display:none;}
      .imgTwist{max-width:100px;bottom:10px;left:-10px;opacity:.7;display:none;}
      p.catR{font-size:18px;@include rotate(0deg);position:relative;opacity:1;text-align:center;max-width:100%;
        bottom:auto;right:auto;margin-right:0;margin-bottom:10px;
      }

      li{font-size:17px;padding-left:11px;
        &::before{font-size:13px;}
      }
      .right{width:100%;}
      ul{@include center-block();max-width:410px;text-align:center;}//max-width:240px;}
      li{display:inline-block;margin:0 5px; 
        &::before{text-align:left;}}
    }
  }

  .ecom3{margin:80px 0;position:relative;
    .titleBox{width:230px;padding:75px 20px;top:18%;left:50%;margin-left:-115px;background-color:$color1;text-align:center;border-radius:50%;position:absolute;z-index:0;
      &::before{@include abs();width:90%;height:90%;left:5%;top:5%;border:1px solid $white;border-radius:50%;opacity:.5;}
      p.title{font-family:$mont;font-size:25px;font-weight:bold;text-transform:uppercase;letter-spacing:3px;color:$white;margin:0;}
    }

    .i1,.i2{margin-bottom:100px;}
    .left{margin-left:-9%;}
    .right{margin-right:-10%;text-align:right;}

    .i2,.i3{position:relative;
      &::after{@include abs();width:150px;height:150px;@include sec-bgimage('./images/pinkLines.png');opacity:.5;}
    }
    .i3::after{left:-70px;bottom:-40px;}
    .i2::after{right:-70px;top:-60px;}


    @media (min-width:1800px){margin:20px 0;}
    @include bp(tx){
      .left{margin-left:-7%;}
      .right{margin-right:-7%;}

      .i3::after{left:-50px;bottom:-30px;}
      .i2::after{right:-50px;top:-50px;}
    }

    @include bp(tw){margin:60px 0 50px;
      .titleBox{width:200px;margin-left:-100px;padding:67px 20px;top:22%;
        p.title{font-size:22px;letter-spacing:2px;}
      }

      .left{margin-left:-1%;}
      .right{margin-right:-1%;}

      .i2,.i3{
        &::after{width:135px;height:135px;}
      }
      .i3::after{left:-35px;bottom:-30px;}
      .i2::after{right:-35px;top:-40px;}
    }

    @include bp(br){margin:40px 0 30px;
      .titleBox{width:170px;margin-left:-97px;padding:57px 20px;top:24%;
        p.title{font-size:19px;letter-spacing:1px;}
      }

      .col p{font-size:18px;}

      .left{margin-left:0;}
      .right{margin-right:0;}

      .i2,.i3{
        &::after{width:115px;height:115px;}
      }
      .i3::after{left:-25px;bottom:-20px;}
      .i2::after{right:-25px;top:-30px;}
    }

    @include bp(tn){margin:30px 0 20px;}

    @include bp(og){margin:10px 0 10px;
      .med2Def{max-width:550px;}

      .titleBox{@include sec-default();position:relative;background-color:transparent;border-radius:0;padding:0;top:auto;left:auto;margin:0 0 15px;
        &::after{display:none;}
        p.title{color:$black;}
      }

      .col{width:100%;margin-top:15px;padding-top:4px;
        p{font-size:17px;}
      }
      .i1,.i2,.i3,.i4{margin-bottom:15px;}
      .i2::after,.i3::after{display:none;}
      .left{padding-left:15px;border-left:3px solid $color2;}
      .right{padding-right:15px;border-right:3px solid $color2;}
    }
  }

  .ecom4{background-color:$f9;position:relative;
    padding:200px 0 30px;//padding:250px 0 30px;
    .slideNav{color:$black;
      &:hover,&:focus{background:rgba($f9,.6);}
    }
    .instr{display:none;}

    .slide{width:100%;position:relative;padding-bottom:60px;  padding-top:50px;}

    .imageMain{width:45%;@include stackB();padding-top:50px;
      &::before{left:-15%;height:85%;width:95%;}
    }
    .imageGroup{width:47%;margin-top:60px;}

    img{box-shadow:0 15px 25px rgba($black,.2);}
    .ei1{@include rotate(-10deg);margin-left:-5%;}
    .ei2{@include rotate(10deg);float:right;margin-right:-5%;}

    .croninSlide .left::before{background-color:$cronin;}
    .barrowSlide .left::before{background-color:darken($barrow, 10%);}
    .spearSlide .left::before{background-color:$spear;}
    .fscSlide .left::before{background-color:#6eb9b9;opacity:.7;}

    .comboSlide{
      .cfolImg{display:inline-block;width:33%;position:relative;z-index:0;padding-top:20px;}
      .frank{float:left;margin-left:-5%;@include rotate(-10deg);}
      .shades{float:right;margin-right:-5%;@include rotate(10deg);}
      .zip{margin-left:6%;margin-top:5%;}
    }

    // @media (min-width:2100px){padding-top:290px;}
    @media (min-width:2100px){padding-top:240px;}

    // @include bp(tl){padding:180px 0 0;}
    @include bp(tl){padding:130px 0 0;
      .comboSlide{
        .frank{margin-left:-2%;}
        .shades{margin-right:-2%;}
        .zip{margin-left:3%;}
      }
    }

    @media (max-width:1330px){
      .arrowLeft{margin-left:5px;}
      .arrowRight{margin-right:5px;}
      .slide{padding:0px 30px 40px 40px;}
      .imageMain::before{left:-12%;width:90%;}

      .comboSlide{
        .frank{@include rotate(-7deg);}
        .shades{@include rotate(7deg);}
      }
    }
    @include bp(tw){padding-top:160px;
      .comboSlide{
        .cfolImg{width:32%;}
        .frank{margin-left:0;}
        .shades{margin-right:0;}
        .zip{margin-left:2%;}
      }
    }
    
    @include bp(tb){
      //padding-top:140px;  .slide{padding:0px 20px 30px 30px;}
      padding-top:110px; .slide{padding:30px 20px 30px 30px;}

      .imageMain{width:46%;padding-top:35px;
        &::before{left:-10%;}
      }
    }

    @include bp(tn){padding-top:100px;
      .slide{padding-top:0px;}
      .slideNav{display:none;}
      .instr{@include sec-default();margin:10px 0 20px;text-align:center;
        p{font-size:15px;}
      }
    }

    @include bp(og){
      .instr{margin-top:0;}
      .slide{padding:0px 10px 20px 10px;}
      .imageMain{width:46%;padding-top:20px;
        &::before{height:60%;left:-20px;width:80%;}
      }
      .imageGroup{margin-top:40px;}
      .ei2{margin-right:0;}
      img{box-shadow:0 11px 25px rgba($black,.15);}

      .comboSlide{
        .cfolImg{width:48%;max-height:520px;overflow:hidden;padding-top:0;}
        .frank{display:none;}
        .shades{@include rotate(0deg);}
        .zip{margin-left:0;margin-top:0;}
      }
    }

    @include bp(mb){padding-top:80px;}
    @include bp(oc){
      .medDef{max-width:600px;}
      .slide{padding:0px 20px 30px 30px;}
      .imageMain{width:90%;max-width:380px;@include center-block();padding:0;
        &::before{left:-10%;width:90%;  display:none;}
      }
      .imageGroup{width:100%;margin-top:-15px;
        .efolImgB{width:95%;max-width:380px;}
      }
      .ei1{margin-left:0;}

      .comboSlide{
        .medDef{max-width:300px;}
        .shades,.zip{width:100%;}//max-height:100%;}
        .shades{margin-right:-5%;@include rotate(-5deg);padding-top:5px;}
        .zip{margin-left:-5%;margin-top:-150px;@include rotate(5deg);}
      }
    }

    @media (max-width:500px){//padding-top:60px;
      .instr p{margin-bottom:0;}
    }
    @include bp(xs){
      .comboSlide{
        .shades{display:none;}
        .zip{max-height:100%;margin-left:0;margin-top:15px;}
      }
    }
  }

  .whyShop{position:relative; margin:100px 0;
    .titleBox{width:290px;padding:90px 20px;left:50%;margin-left:-145px; top:24%;//top:29%;
      background-color:$color1;text-align:center;border-radius:50%;position:absolute;z-index:0;
      &::before{@include abs();width:90%;height:90%;left:5%;top:5%;border:1px solid $white;border-radius:50%;opacity:.5;}
      p.title{font-family:$mont;font-size:24px;text-transform:uppercase;letter-spacing:3px;color:$white;margin:0;font-weight:normal;}
    }

    .left{width:40%;margin-left:-9%;
      .item{float:left;}
    }
    .right{width:36%;margin-right:-8%;text-align:right;
      .item{float:right;}
    }

    .item{width:100%;display:block;margin-bottom:50px; margin-top:25px;position:relative;z-index:0;
      &::after{@include abs();@include sec-bgimage('./images/pinkLines.png');opacity:.5;}
    }
    .cblock{text-align:center;}
    .l1{margin-left:-14%;margin-bottom:30px;}
    .r1{margin-right:5%;}
    .r2{margin-right:-15%;width:110%;}
    .bottomc{margin-top:30px;}
    
    .l1::after,.l2::after{left:-30px;}
    .r1::after,.r2::after{right:-30px;}
    .l1::after{width:130px;height:130px;top:-25px;}
    .l2::after{width:100px;height:80px;bottom:-15px;}
    .r1::after{width:120px;height:120px;top:-20px;}
    .r2::after{width:100px;height:80px;bottom:-15px;}

    @media (max-width:1300px){
      .titleBox{width:270px;padding:85px 20px;top:25%;
        p.title{font-size:22px;}
      }

      .topc{margin-bottom:40px;}
      .left{margin-left:-4%;}
      .right{margin-right:-6%;}
      .r2{margin-right:0;margin-top:0;width:100%;}
      .bottomc{margin-top:0;}
    }

    @include bp(tw){margin:80px 0 60px;
      .titleBox{margin-left:-128px;width:255px;padding:80px 20px;
        p.title{font-size:21px;letter-spacing:1px;
          b{letter-spacing:4px;}
        }
      }

      .topc{margin-bottom:40px;}
      .left{width:37%;margin-left:2%;}
      .right{width:34%;margin-right:-2%;}
      .r2{margin-right:5%;}
    }

    @include bp(tb){margin:60px 0 30px;
      .titleBox{width:240px;margin-left:-102px;padding:78px 20px;
        p.title{font-size:19px;}
      }
      .col p{font-size:18px;}
      .topc,.bottomc{width:55%;}
      .left{margin-left:5%;}
    }

    @include bp(ns){margin:40px 0 0px;text-align:center;
      .med2Def{max-width:730px;max-width:580px;}

      .titleBox{width:100%;margin-left:0;padding:0;position:relative;top:auto;left:auto;text-align:center;border-radius:0;background-color:transparent;margin-bottom:20px;
        &::after{display:none;}
        p.title{color:$color1;letter-spacing:0;
          b{letter-spacing:0;}
        }
      }

      .left{width:100%;margin-left:0;}
      .right{width:100%;margin-right:0;text-align:center;}

      .item,.cblock{width:100%;margin:0;padding-bottom:20px;
        &::after{display:none;}
        &::before{@include abs();width:5px;height:5px;left:50%;margin-left:-2.5px;border-radius:50%;background-color:$color1;bottom:11px;}
      }
      .cblock{position:relative;}

      .col p{font-size:17px;}
      .col p br{display:none;}
    }

    @include bp(oc){margin-top:25px;
      .titleBox{margin-bottom:10px;
        p.title{max-width:250px;@include center-block();}
      }

      .item,.cblock{padding-bottom:15px;
        &::before{bottom:8px;}
      }
    }
  }
}  
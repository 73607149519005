@import '../../assets/global';
@import '../../assets/variables';
@import '../../assets/mixins';

.Footer{@include sec-default();position:relative;z-index:0;
  &.f9Style{background-color:$f9;}


  .ftTop{@include sec-default();position:relative;z-index:0;margin-top:30px;padding:120px 0 100px;
    &::before,&::after{@include before();width:45%;}
    &::before{background-color:rgba($color1-lt,.97);}
    &::after{@include sec-bgimage('./images/ogHs2.jpg');background-size:cover;z-index:-2;}
  }
  
    
  .contactSec{@include sec-default();position:relative;z-index:0; padding:80px 0 110px;
    &::before{@include after();width:55%;right:0;background-color:$color1;}
  }

  .introText{margin-bottom:20px;margin-left:5%;}

  .formSide,.infoSide,.hlf{display:inline-block;}
  .formSide{width:60%;float:left;margin-left:-5%;}
  .infoSide{width:40%;float:right;margin-top:20%;}

  form{@include sec-default();padding:30px;background-color:$white;
    p{color:darken($color1-dk, 10%);font-size:17px;margin-bottom:20px;}
  }
  .formGroup{margin-bottom:20px;}
  .formGroup:not(.fgdets){display:inline-block;width:48%;
    &+.formGroup{float:right;}
    // &:nth-child(2n+2){float:right;}
  }
  .formGroup.fgsub{@include sec-default();margin-bottom:0;
    .successSub,.errorSub{margin:10px 0 0;}
    .successSub p{color:$color2;}
    .errorSub p{color:$red;}

    .btnRow{width:48%;float:right;margin-top:0;}
  }

  label{font-size:16px;}
  .cfield{@include sec-default();font-family:$body;padding:3px 3px 3px 5px;border:none;border-bottom: 2px solid rgba($black,.1);background-color:$silver;font-size:15px;}
  input{min-height:35px;}
  textarea{min-height:100px;}
  .hsLink{float:right;margin-top:20px;font-size:14px;background-color:darken($color1, 15%);}

  .hlf{width:49%;margin-top:20px;
    &.hlf1{float:left;}
    &.hlf2{float:right;margin-right:-2%;}
  }
  
  
  .modalBtn{padding:0;font-weight:bold;text-align:left;font-size:16px;text-shadow:0px 1px 1px rgba($color1-dk, .9);}
  p.secTitle{color:$color2;margin-bottom:0;}
  .titleLine{width:50px;height:2px;background-color:rgba($color2,.75);margin:-5px 0 30px;margin-bottom:10px;}
  
  .cblock{@include sec-default();margin-bottom:30px; padding-left:5px;@include stackB(); 
    &::before{left:-5px;border-left:2px solid rgba($color2,.5);}

    p,a,svg,.modalBtn{color:$white;margin-bottom:0;}
    p.cat{color:$color2;font-size:14px;line-height:1em;}
    p,a,svg{font-size:17px;}
    br.tw{display:none;}
  }


  .FooterBtm{position:absolute;width:42%;bottom:20px;right:0;
    p{font-size:15px;margin-bottom:0;}
  }



  .partnerRowUp{@include sec-default();text-align:center;margin:40px 0 10px;
    .prInner{width:95%;max-width:1080px;@include center-block();}
    .partner{display:inline-block;max-width:180px;margin:10px 10px 0px;vertical-align:top;}//padding:10px;}
    .google{max-width:100px;margin-top:0;}
    .bing{max-width:120px;margin-top:25px;}
    .vend{max-width:160px;margin-left:0;}
    .vantiv{margin-right:0px;margin-left:0px;}
  }
  .FooterBtmB{text-align:center;
    p{font-size:14px;margin-bottom:0;}
  }

  @media (max-width:1450px){.introText{margin-left:17%;}}
  @include bp(tx){
    .ftTop{padding:100px 0 80px;}
    .contactSec{padding-top:60px;}
    .formSide{width:58%;margin-left:-3%;}
    .infoSide{width:42%;margin-right:-1%;}
  }

  @include bp(tw){
    .infoSide{width:43%;margin-right:-1.5%;}
    form{padding:30px 20px;
      p{width:90%;@include center-block();}
    }
    
    .modalBtn{font-size:15px;}
    .hlf.hlf2{margin-right:-3%;}
    .cblock{
      p,a,svg{font-size:16.5px;}
      br.tw{display:block;}
      svg{width:17px;}
    }
  }

  @include bp(br){
    .ftTop{padding:70px 0 50px;}
    .contactSec{padding:20px 0 60px;}
    .medDef{max-width:740px;}
    .introText{margin-bottom:5px;}
    .formSide{width:62%;margin-left:0;}
    .infoSide{width:33%;margin-right:0;margin-top:20%;}

    form{padding:30px;
      p{width:100%;font-size:16px;}
    }
    .formGroup:not(.fgdets){width:100%;}

    .hlf{width:100%;
      &.hlf2{margin:0;}
    }
    p.secTitle{font-size:30px;}

    .FooterBtm{bottom:10px;width:55%;text-align:center;
      p{font-size:14px;}
    }
  }

  @include bp(og){
    .ftTop{padding:30px 0;}
    .contactSec{padding:10px 0 40px;}
    .introText{margin-left:10%;}

    form{padding:20px;}
    label{font-size:15px;}
    .hsLink{font-size:13px;}
    p.secTitle{font-size:25px;}
    .FooterBtm{bottom:2px;width:100%;}
  }

  @include bp(oc){
    .ftTop{padding:0;&::before,&::after{width:100%;height:50%;}}//display:none;}
    .contactSec{padding:20px 0px;margin-bottom:5px;
      &::before{width:100%;height:60%;}
    }

    .formSide,.infoSide{width:100%;}
    .infoSide{margin-top:0;}
    
    .introText{margin-left:0;margin-top:20px;}
    form p,.cblock{text-align:center;}
    .cblock{
      &::before{display:none;}
      p,svg,a,.modalBtn{color:$black;}
    }
    p.secTitle{@include center-block();max-width:250px;text-align:center;}
    .titleLine{display:none;}
    .hlf1{margin-top:10px;}

    .FooterBtm{position:relative;padding:10px 0 5px;bottom:auto;}
  }
}